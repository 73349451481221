<template>
  <div id="box">
    <div id="from">
      <h2>书云心理健康注册系统</h2>
      <el-form ref="form" :model="user" label-width="80px">
        <el-input
          v-model="user.name"
          placeholder="请输入用户名"
          class="zh"
        ></el-input>
        <el-input
          v-model="user.pass"
          placeholder="请输入密码"
          show-password
        ></el-input>
        <el-button type="primary" :loading="loading" @click="Register"
          >注册</el-button
        >
        <el-link type="primary" @click="toLogin">去登录</el-link>
      </el-form>
    </div>
  </div>
</template>

<script>
import { Message } from "element-ui";
import api from "../../http/api";
export default {
  data() {
    return {
      loading: false,
      user: { name: "", pass: "" },
    };
  },
  methods: {
    // 注册接口
    async Register() {
      const data = await api.login.Register(this.user);
      if (data.data.data == true) {
        console.log(22222, data);
        Message.success("注册成功，已为您跳转到登录页面");
      } else {
        Message.success("注册失败，可能是网络问题，请刷新页面后重试");
      }
    },
    toLogin() {
      this.$router.push("/login");
    },
  },
};
</script>
<style lang='scss' scoped>
#box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  padding: 0;
  background: rgb(117, 212, 241);
  #from {
    width: 400px;
    height: 250px;
    padding: 20px;
    text-align: center;
    background-color: white;
    border-radius: 15px;
    h2 {
      margin: 20px;
      font-size: 24px;
    }
    .zh {
      margin-bottom: 25px;
    }
    button {
      margin-top: 25px;
    }
  }
}
</style>
